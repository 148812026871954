import { Component, Input, OnChanges, SimpleChanges, numberAttribute } from '@angular/core';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { PartCD, SummaryData } from 'src/app/summary-data';
import { exportExcelFile } from './summary-export-excel';
import { saveAs } from 'file-saver';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
  animations: [
    trigger('detailExpandPartC', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailExpandPartD', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class SummaryComponent implements OnChanges {
  constructor(
    private apiService: ApiServiceService,
    private router: Router,
  ) {}

  @Input({
    transform: (value: unknown) => numberAttribute(value, undefined),
    required: true
  }) recordId?: number;

  data?: SummaryData;
  setMaxlength = false;
  dateFormat: string = 'MM/dd/yyyy';
  partCOpenState = true;
  partDOpenState = true;

  displayedColumns_PartC: string[] = ['ActivePlan', 'EnrollmentDate', 'TerminationDate', 'Name', 'ContractNumber', 'PBPNumber'];
  displayedColumns_PartD: string[] = ['ActivePlan', 'EnrollmentDate', 'TerminationDate', 'Name', 'ContractNumber', 'PBPNumber'];
  columnsToDisplayWithExpandPartC = ['expand'].concat(this.displayedColumns_PartC);
  columnsToDisplayWithExpandPartD = ['expand'].concat(this.displayedColumns_PartD);

  displayedColumns_AmmendedPartC = ['ActivePlan', 'EnrollmentDate', 'TerminationDate', 'Name', 'ContractNumber', 'PBPNumber'];
  displayedColumns_AmmendedPartD = ['ActivePlan', 'EnrollmentDate', 'TerminationDate', 'Name', 'ContractNumber', 'PBPNumber'];

  expandedElement_PartC: PartCD | undefined;
  expandedElement_PartD: PartCD | undefined;

  // searchReturnHref(): string {
  //   if (document.referrer.includes(document.location.host) && document.referrer.includes('/search?')) {
  //     return document.referrer;
  //   } else {
  //     return 'search';
  //   }
  // }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.recordId == undefined) {
      console.warn('invalid recordId')
      return;
    }
    this.apiService.summary(this.recordId).subscribe(
      (result) => {
        this.data = result;
        if (
          (this.data.ClaimNumber != null && this.data.ClaimNumber.length > 15) || 
          (this.data.FirstName != null && this.data.FirstName.length > 15) || 
          (this.data.LastName != null && this.data.LastName.length > 15)) {
            this.setMaxlength = true;
        }

        if(!this.data.IsAuthorized){
          this.router.navigate(['unauthorized', { type: 'summary_unathorized' }]);
        }
      }
    );
  }

  getDateInYYYYMMDDHHMMSS() {
    let date = new Date()
    let y = date.getFullYear();
    let m = String(date.getMonth() + 1).padStart(2, '0');
    let d = String(date.getDate()).padStart(2, '0');
    let hh = String(date.getHours()).padStart(2, '0');
    let mm = String(date.getMinutes()).padStart(2, '0');
    let ss = String(date.getSeconds()).padStart(2, '0');
    
    return `${y}${m}${d}${hh}${mm}${ss}`;
}

  exportToExcel() {
    exportExcelFile(this.data).then(buffer => {
      if(this.data != undefined)
        {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          let filename = `MAP_${this.data.LastName}_${this.data.FirstName}_${this.data.ClaimNumber}_${this.data.ClaimantId}_${this.getDateInYYYYMMDDHHMMSS()}`;
          saveAs(blob, `${filename}.xlsx`);
        }
    });
}
}