export class SearchQuery {
  constructor(
    public claimNumber: string = '',
    public mbi: string = '',
    public firstName: string = '',
    public lastName: string = '',
    public dateOfBirth: string = '',
    public dob: string = '',
    public ssn: string = '',
    public orderBy: OrderBy = 'claimno',
    public orderDir: 'ASC' | 'DESC' = 'ASC'
  ) {}

  apiBody(): any {
    return {
      ClaimNo: this.claimNumber,
      MedicareId: this.mbi,
      FirstName: this.firstName,
      LastName: this.lastName,
      DOB: this.dateOfBirth
    }
  }
}

// No option is same as claimno
export type OrderBy = '' | 'claimno' | 'medicareid' | 'firstname' | 'lastname' | 'parta_eff_date';
