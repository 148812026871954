import { inject, Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../components/dialog/dialog.component';


@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  readonly dialog = inject(MatDialog);
  constructor(public _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) { }

  async logout() {
    await this.oktaAuth.signOut();
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, title: string, content: string): void {
    this.dialog.open(DialogComponent, {
      width: 'auto',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        title: title,
        content: content
      }
    });
  }
}
