import * as ExcelJS from 'exceljs';
import { SearchResult } from 'src/app/services/api-service.service';

export function searchExportExcelFile(data?: SearchResult): Promise<ExcelJS.Buffer> {
    const startRow = 1;

    function addHeader(sheet) {
        sheet.getRow(startRow).values = ['Claim Number', 'Medicare ID', 'Last Name', 'First Name', 'Date of Entitlement'];
    };

    //Changing the color of table 
    function changeHeaderColor(sheet) {
        const header = sheet.getRow(1);
        header.eachCell((cell, colNumber) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' }},
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00629d' }}
        });
    }
    
    function setColumnWidth(sheet) {
        //Setting width for the columns
        sheet.getColumn(1).width = 15; 
        sheet.getColumn(2).width = 15; 
        sheet.getColumn(3).width = 25;
        sheet.getColumn(4).width = 25;
        sheet.getColumn(5).width = 25;
        //sheet.getColumn(4).alignment = { wrapText: true };
    }

    const workbook = new ExcelJS.Workbook();
    let sheet = workbook.addWorksheet('Search Results');
    addHeader(sheet);

    if(data != undefined){
        if(data.PaidActData != undefined && data.PaidActData.length > 0)
        {
            let i = 1;
            data.PaidActData.forEach(function(item){
                sheet.getRow(startRow + i).values = [item.ClaimNo, item.MedicareId, item.LastName, item.FirstName, item.DateOfEntitlement]
                i = i + 1;
            });
        }
        changeHeaderColor(sheet);
        setColumnWidth(sheet);  
    }

    return workbook.xlsx.writeBuffer();
}