import { Directive, ElementRef, forwardRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appSsnMask]',
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SsnMaskDirective),
        multi: true,
    },
],
})
export class SsnMaskDirective implements ControlValueAccessor{

  private fullSSN = '';
    constructor(private el: ElementRef) { }
    @HostListener('focus')
    onFocus(): void {
        this.el.nativeElement.value = this.formatSSN(this.fullSSN);
    }
    // @HostListener('blur')
    // onBlur(): void {
    //     this.el.nativeElement.value = this.maskSSN(this.fullSSN);
    //     this.onTouched();
    // }
    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.fullSSN = input.value.replace(/\D/g, ''); // Remove all non-digit characters
        if (this.fullSSN.length > 9) {
            this.fullSSN = this.fullSSN.slice(0, 9); // Limit input to 9 digits
        }
        input.value = this.formatSSN(this.fullSSN);
        this.onChange(this.fullSSN);
    }
    // ControlValueAccessor implementation
    writeValue(value: string): void {
        this.fullSSN = value || '';
        // this.el.nativeElement.value = this.maskSSN(this.fullSSN);
        this.el.nativeElement.value = this.fullSSN;
    }
    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    private onChange: (value: string) => void = () => { };
    private onTouched: () => void = () => { };
    // private maskSSN(value: string): string {
    //     if (value.length <= 4) {
    //         return 'X'.repeat(value.length);
    //     }
    //     // const maskedSection = 'X'.repeat(value.length - 4);
    //     const visibleSection = value.slice(-4);
    //     return `XXX-XX-${visibleSection}`;
    // }
    private formatSSN(value: string): string {
        if (value.length > 5) {
            return value.replace(/(\d{3})(\d{2})(\d{0,4})/, '$1-$2-$3');
        } else if (value.length > 3) {
            return value.replace(/(\d{3})(\d{0,2})/, '$1-$2');
        } else {
            return value;
        }
    }
}
