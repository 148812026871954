import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { SearchQuery } from '../../search-query';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { CommonserviceService } from 'src/app/services/commonservice.service';
import { searchExportExcelFile } from './search-export-excel';
import { SearchResult } from 'src/app/services/api-service.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnInit {
  constructor(
    private commonservice: CommonserviceService
  ) {  }
  readonly dialog = inject(MatDialog);
  model: SearchQuery = new SearchQuery();
  public searchForm!: FormGroup;
  searchResults !: SearchResult;
  EnableExport = false;

  @Input() initialState?: SearchQuery;
  @Input() warningMessages: string[] = [];

  // @Output() searchEvent: EventEmitter<SearchQuery> = new EventEmitter<SearchQuery>();
  @Output() searchEvent: EventEmitter<{ query: SearchQuery; callback: (response: SearchResult) => void }> = new EventEmitter();
  @Output() exportEvent: EventEmitter<{ query: SearchQuery; callback: (response: SearchResult) => void }> = new EventEmitter();

  ngOnInit(): void {
    if (this.initialState != undefined) {
      this.model = this.initialState;
    }
    this.clearFormValues();
  }

  allSearchFieldsEmpty() {
    const { claimNumber, mbi, dateOfBirth, ssn, firstName, lastName } = this.model;    
    const fieldsEmpty = [claimNumber, mbi, dateOfBirth, ssn, firstName, lastName].every(field => field.trim() === "");
    const firstLastNameConflict = (firstName.trim() === "" && lastName.trim() !== "") || 
                                  (firstName.trim() !== "" && lastName.trim() === "");
    return fieldsEmpty || firstLastNameConflict;
  }

  search() {
    if(this.searchForm.valid){
      this.model.claimNumber = this.searchForm.get('claimNumber')?.value;
      this.model.mbi = this.searchForm.get('mbi')?.value;
      this.model.dateOfBirth = this.searchForm.get('dateOfBirth')?.value;
      this.model.firstName = this.searchForm.get('firstName')?.value;
      this.model.lastName = this.searchForm.get('lastName')?.value;
      this.model.ssn = this.searchForm.get('ssn')?.value;

      if(this.model.dateOfBirth != "" && this.model.dateOfBirth != null) {
        var date = new Date(this.model.dateOfBirth);
        this.model.dateOfBirth = String(date.getFullYear()) + String((date.getMonth() + 1)).padStart(2, '0') + String(date.getDate()).padStart(2, '0');
      }

      if(this.allSearchFieldsEmpty()){
        this.commonservice.openDialog('500ms', '300ms', 'Error', "<span>Please enter at least one field value to search. <br>First and last name are considered one field.</span>");
        return;
      }
      console.log('search submitted');
      //this.searchEvent.emit(this.model);
      this.searchEvent.emit({
        query: this.model,
        callback: (response: SearchResult) => {
          var count = response.TotalRecords;
          this.EnableExport = count > 0 && count <= 100;
        }
      });
    }
  }

  exportToExcel() {
    this.exportEvent.emit({
        query: this.model,
        callback: (response: SearchResult) => {
          this.searchResults = response;
          this.export();
        }
    });
  }

  export() {
    searchExportExcelFile(this.searchResults).then(buffer => {
      if(this.searchResults != undefined)
        {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          let filename = 'Map Search Results';
          saveAs(blob, `${filename}.xlsx`);
        }
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.searchForm.controls[controlName].hasError(errorName);
  }

  readFormValues() {
    this.model.claimNumber = this.searchForm.get('claimNumber')?.value;
    this.model.mbi = this.searchForm.get('mbi')?.value;
    this.model.dateOfBirth = this.searchForm.get('dateOfBirth')?.value;
    this.model.firstName = this.searchForm.get('firstName')?.value;
    this.model.lastName = this.searchForm.get('lastName')?.value;
    this.model.ssn = this.searchForm.get('ssn')?.value;
  }

  clearFormValues() {
    this.searchForm = new FormGroup({
      claimNumber: new FormControl(''),
      mbi: new FormControl('',[Validators.minLength(10)]),
      ssn: new FormControl('', [Validators.pattern('[0-9]*'), Validators.minLength(9)]),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      dateOfBirth: new FormControl('')
    });
  }

  clear(){
    this.clearFormValues();
  }

  reset() {
    // would need an event probably to reset pagination and clear the results
    console.log('search reset');
    this.model = new SearchQuery();
  }
}
