import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { OktaAuthStateService ,OKTA_AUTH} from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { ApiServiceService } from './services/api-service.service';
import { CommonserviceService } from './services/commonservice.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    isAuthenticated!: boolean;
    username?: string;

  constructor(
    private router: Router, 
    public _oktaStateService: OktaAuthStateService, 
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private apiService: ApiServiceService,
    private commonservice: CommonserviceService
  ) { }

  async canActivate(): Promise<boolean> {
    const isAuthenticated = await this._oktaAuth.isAuthenticated();
    var isAuthorized : boolean | undefined;
    isAuthorized = await this.apiService.isUserInGroup().toPromise();
    if (isAuthorized == undefined)
        this.router.navigate(['unauthorized']);

    if (isAuthorized) {
        const userClaims = await this._oktaAuth.getUser();
        this.username = userClaims['lastName'] + ', ' + userClaims['firstName'];
        this.apiService.checkUser(this.username).subscribe(
        (result) => {
            if(!result){
                // alert("CheckUser - System error occurred.");
                this.commonservice.openDialog('500ms', '300ms', 'Error', "CheckUser - System error occurred.");
            }
        });
        return true;
    } else {
        this.router.navigate(['unauthorized']);
        return false;
    }
  }


}
