import * as ExcelJS from 'exceljs';
import { SummaryData } from 'src/app/summary-data';

export function exportExcelFile(data?: SummaryData): Promise<ExcelJS.Buffer> {
    const startRow = 11;
    const rowHeight = 60;

    function replaceCommas(input: string): string {
        let i = 0;
        return input.replace(/,/g, (match) => {
          i++;
          return i <= 2 ? '\n' : match;
        });
    }

    function addHeader(data, sheet) {
        const nameRow = sheet.addRow(["First Name + Last Name", data?.FirstName + " " + data?.LastName]);
        nameRow.getCell(1).font = { bold: true};
        nameRow.getCell(2).font = { bold: true};

        const claimNoRow = sheet.addRow(["Claim Number", data?.ClaimNumber]);
        claimNoRow.getCell(1).font = { bold: true};
        claimNoRow.getCell(2).font = { bold: true};

        const mbiRow = sheet.addRow(["MBI", data?.MedicareId]);
        mbiRow.getCell(1).font = { bold: true};
        mbiRow.getCell(2).font = { bold: true};
        sheet.addRow(["", ""]);

        sheet.addRow(["Part A Effective Date", data?.PartAEffectiveDate]);
        sheet.addRow(["Part A Termination Date", data?.PartATerminationDate]);
        sheet.addRow(["Part B Effective Date", data?.PartBEffectiveDate]);
        sheet.addRow(["Part B Termination Date", data?.PartBTerminationDate]);   
        sheet.addRow(["", ""]);
    };

    //Changing the color of table 
    function changeHeaderColor(sheet, rowNo) {
        const header = sheet.getRow(rowNo);
        header.eachCell((cell, colNumber) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' }},
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00629d' }}
        });
    }
    
    function setColumnWidth(sheet) {
        //Setting width for the columns
        sheet.getColumn(1).width = 25; 
        sheet.getColumn(2).width = 25; 
        sheet.getColumn(3).width = 25;
        sheet.getColumn(4).width = 50;
        sheet.getColumn(5).width = 25;
        sheet.getColumn(6).width = 25;

        sheet.getColumn(4).alignment = { wrapText: true };
    }

    function addTableHeader(sheet, tableHeader, startCell, mergeCells) {
        sheet.getCell(startCell).value = tableHeader;
        sheet.mergeCells(mergeCells);
        const partcMergedCell = sheet.getCell(startCell);
        partcMergedCell.font = { size: 12, bold: true };
        partcMergedCell.alignment = { vertical: 'middle', horizontal: 'center' };
    }

    const workbook = new ExcelJS.Workbook();
    let sheet = workbook.addWorksheet('Enrollment Data');
    addHeader(data, sheet);
    setColumnWidth(sheet);

    let i = 1;
    if(data != undefined){
        //Part C records
        if(data.PartC != undefined && data.PartC.length > 0)
        {
            addTableHeader(sheet, "Part C", "A10", "A10:F10");
            sheet.addRow(['Plan Status', 'Enrollment Date', 'Termination Date', 'Name and Address', 'Contract Number', 'PBP Number']);

            data.PartC.forEach(function(item){
                item.PaidData.TerminationDate = item.PaidData.TerminationDate.trim() == '- -' ? "" : item.PaidData.TerminationDate;
                item.PaidData.NameAddress = replaceCommas(item.PaidData.NameAddress);
                sheet.getRow(startRow + i).height = rowHeight;
                sheet.getRow(startRow + i).values = [item.PaidData.ActivePlan, item.PaidData.EnrollmentDate, item.PaidData.TerminationDate, item.PaidData.NameAddress, item.PaidData.ContractNumber, item.PaidData.PBPNumber];
                i = i + 1;

                //Amended Records
                item.PaidDataHistory.forEach(function(amendedItem){
                    amendedItem.TerminationDate = amendedItem.TerminationDate.trim() == '- -' ? "" : amendedItem.TerminationDate;
                    amendedItem.NameAddress = replaceCommas(amendedItem.NameAddress);
                    sheet.getRow(startRow + i).height = rowHeight;
                    sheet.getRow(startRow + i).values = [amendedItem.ActivePlan, amendedItem.EnrollmentDate, amendedItem.TerminationDate, amendedItem.NameAddress, amendedItem.ContractNumber, amendedItem.PBPNumber];
                    i = i + 1;
                });
            });
            changeHeaderColor(sheet, startRow);
            sheet.addRow(["", ""]);
            sheet.addRow(["", ""]);
        }        

        // Part D records
        if(data.PartD != undefined && data.PartD.length > 0)
        {
            let nextRow = sheet.rowCount + 1;
            let startCell = "A"+nextRow.toString();
            let partdMergeCells = "A" + nextRow.toString() + ":F" + nextRow.toString();
            addTableHeader(sheet, "Part D", startCell, partdMergeCells);

            let partdRow = nextRow + 1;
            sheet.addRow(['Plan Status', 'Enrollment Date', 'Termination Date', 'Name and Address', 'Contract Number', 'PBP Number']);
            changeHeaderColor(sheet, partdRow);

            partdRow = partdRow + 1;
            data.PartD.forEach(function(item){
                item.PaidData.TerminationDate = item.PaidData.TerminationDate.trim() == '- -' ? "" : item.PaidData.TerminationDate;
                item.PaidData.NameAddress = replaceCommas(item.PaidData.NameAddress);
                sheet.getRow(partdRow).height = rowHeight;
                sheet.getRow(partdRow).values = [item.PaidData.ActivePlan, item.PaidData.EnrollmentDate, item.PaidData.TerminationDate, item.PaidData.NameAddress, item.PaidData.ContractNumber, item.PaidData.PBPNumber];
                partdRow = partdRow + 1;

                //Amended Records
                item.PaidDataHistory.forEach(function(amendedItem){
                    amendedItem.TerminationDate = amendedItem.TerminationDate.trim() == '- -' ? "" : amendedItem.TerminationDate;
                    amendedItem.NameAddress = replaceCommas(amendedItem.NameAddress);
                    sheet.getRow(startRow + partdRow).height = rowHeight;
                    sheet.getRow(startRow + partdRow).values = [amendedItem.ActivePlan, amendedItem.EnrollmentDate, amendedItem.TerminationDate, amendedItem.NameAddress, amendedItem.ContractNumber, amendedItem.PBPNumber];
                    partdRow = partdRow + 1;
                });
            });
        }
    }

    return workbook.xlsx.writeBuffer();
}