<div id="searchFields" class="searchcontainer container">
  <!-- <div class="vui-comp-render mb-3"><h2 class="vui-phDefault">Claim Search</h2></div> -->
  <p>To search for a claim, fill in one or more values below.</p>
  <div class="card"> 
    <form [formGroup]="searchForm" class="text-start">
      <div>
        <div class="row">
          <div class="col">
            <div class="search-label">
              <label for="claimNumber" class="col-form-label col-label" >Claim Number</label>
            </div>
            <mat-form-field class="search-form-field">
              <input matInput placeholder="Enter Claim Number" id="claimNumber" formControlName="claimNumber">
            </mat-form-field>   
            <img src="assets/info_outline.png" width="15px" height="15px" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="When searching by claim number, user input will have leading 'WC', dashes and spaces removed before searching."/>         
          </div>

          <div class="col">
            <div class="search-label">
              <label class="col-form-label col-label">MBI (Full)</label>
            </div>
            <mat-form-field class="search-form-field">
              <input matInput placeholder="Enter MBI (Full)" formControlName="mbi" id="mbi" maxlength="11">
            </mat-form-field>            
          </div>       
          
          <div class="col">
            <div class="search-label">
              <label class="col-form-label col-label">SSN (Full)</label>
            </div>
            <mat-form-field class="search-form-field">
              <input matInput formControlName="ssn" type="text" inputmode="numeric" appSsnMask autocomplete="off" placeholder="Enter SSN" maxlength="11">
              <mat-error *ngIf="hasError('ssn', 'pattern')">Only numeric characters are allowed.</mat-error>
            </mat-form-field>            
          </div>

          <div class="col">
            <div class="search-label">
              <label class="col-form-label col-label">Date of Birth</label>
            </div>
            <mat-form-field class="search-form-field">
              <mat-label>MM/DD/YYYY</mat-label>
              <input matInput [matDatepicker]="picker" id="dateOfBirth" formControlName="dateOfBirth"> 
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>    
          </div> 

          <div class="col">
            <div class="search-label">
              <label class="col-form-label col-label">First Name</label>
            </div>
            <mat-form-field class="search-form-field">
              <input matInput placeholder="Enter First Name" formControlName="firstName" id="firstName">
            </mat-form-field>            
          </div> 

          <div class="col">
            <div class="search-label">
              <label class="col-form-label col-label">Last Name</label>
            </div>
            <mat-form-field class="search-form-field">
              <input matInput placeholder="Enter Last Name" id="lastName" formControlName="lastName">
            </mat-form-field>            
          </div>

          <div class="col">

          </div>

          <div class="col">
            <div class="button-container">
              <button class="vui-btn vui-secondary-btn" (click)="search()" >Search</button>
              <button class="vui-btn vui-secondary-btn btnclear" (click)="clear()">Clear</button>   
              <button *ngIf="EnableExport" class="vui-btn vui-secondary-btn export-button" (click)="exportToExcel()">Export</button>
            </div>
          </div>
        </div>        
      </div>
        <div class="row">
          
        </div>
    </form>
  </div>
  <div class="alert alert-warn" *ngIf="warningMessages.length > 0">
    <div *ngFor="let message of warningMessages">
      {{message}}
    </div>
  </div>
</div>
